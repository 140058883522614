<template>
  <div class="popup_wrap" style="width:400px;">
    <button class="layer_close" @click="popClose()">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <div class="circle_icon check" style="margin:30px auto 0;"></div>

      <p class="msg text_center color_blue font_16 font_medium mt15">
        {{ $t('msg.ONEX020P130.076') }}
      </p>

      <p class="msg mt15 text_left">
        {{ $t('msg.ONEX020P130.064') }} <br />
        {{ $t('msg.ONEX020P130.065') }} <br /> {{ $t('msg.ONEX020P130.066') }}
      </p>

      <div class="text_center mt30">
        <a class="button blue lg" @click="popClose()">{{ $t('msg.ONEX020P130.061') }}</a>
      </div>
    </div><!-- popup_cont -->
  </div>
</template>

<script>
export default {
  name: 'ContainerGuidePop',
  components: {
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    popClose () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.doc_req_pop {width:910px; height: 600px; overflow-y: scroll;}
.doc_req_pop > .layer_close {z-index: 10;}
.div_cntr_area + .div_cntr_area {margin-top: 5px;}
.text_link {color: #000; padding-left: 2px;}
.text_link:hover {text-decoration: underline !important;}
.attdel {cursor: pointer;}
</style>
