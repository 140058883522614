var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "400px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.popClose()
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("div", {
          staticClass: "circle_icon check",
          staticStyle: { margin: "30px auto 0" },
        }),
        _c(
          "p",
          {
            staticClass: "msg text_center color_blue font_16 font_medium mt15",
          },
          [_vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P130.076")) + " ")]
        ),
        _c("p", { staticClass: "msg mt15 text_left" }, [
          _vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P130.064")) + " "),
          _c("br"),
          _vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P130.065")) + " "),
          _c("br"),
          _vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P130.066")) + " "),
        ]),
        _c("div", { staticClass: "text_center mt30" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              on: {
                click: function ($event) {
                  return _vm.popClose()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX020P130.061")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }